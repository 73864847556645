@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App {
  font-family: "Heebo", sans-serif;
  height: 100vmin;
}

.ant-btn {
  font-size: 14px;
}

.App section.ant-layout {
  background-color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #0A0A0A;@font-size-base: 16px;@text-color: #0A0A0A;@link-color: #1890ff;@select-item-selected-color: #FFFFFF;